const config = {
  companyName: 'Rayyonit',

  builder: {
    logo: 'https://api2.raayonit.co.il/upload/17ca2286-24fc-4d47-938d-8e7830c4a341.png',
    theme: {
      palette: {
        primary: {
          lighter: '#7F7E7E',
          light: '#464646',
          main: '#000000',
          dark: '#000000',
          darker: '#030101',
          contrastText: '#FFFFFF',
        },
        secondary: {
          lighter: '#23BAFC',
          light: '#06B4FF',
          main: '#009ADF',
          dark: '#006E9D',
          darker: '#00577C',
          contrastText: '#FFFFFF',
        },
      },
    },
  },
};

export default config;
